<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            SỬA THÔNG TIN LỆNH
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify "
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <h5 class="font-14 font-italic color-warning text-xs-center mb-2">
                (*Lưu ý: Chỉnh sửa thông tin lệnh không bao gồm sửa thông tin chuyến đi
                bán vé)
            </h5>
            <div class="px-2 pb-5">
                <div class="row mb-2">
                    <div class="xs12">
                        <DxCheckBox
                            v-if="
                                ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe',
                                    )
                                ] == 'true'
                            "
                            v-model="
                                ThongTinChinhSuaLenh.TuDongKyLenhKhiCanBoXacNhanKiemTraXe
                            "
                            text="Tự động ký lệnh khi cán bộ kiểm tra xe xác nhận"
                            class="mr-3"
                        />
                        <DxCheckBox
                            v-else-if="
                                $Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.KyLenh'),
                                ) ||
                                $Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.KyLaiLenh'),
                                )
                            "
                            v-model="ThongTinChinhSuaLenh.KyLaiLenh"
                            text="Ký lại lệnh"
                            class="mr-3"
                        />
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxTextBox
                            label="Tuyến vận chuyển"
                            labelMode="floating"
                            styling-mode="underlined"
                            v-model="ThongTinChinhSuaLenh.TenTuyen"
                            :readOnly="true"
                        />
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxTextBox
                            label="Bến xuất phát"
                            labelMode="floating"
                            styling-mode="underlined"
                            v-model="ThongTinChinhSuaLenh.BenDi_TenBenXe"
                            :readOnly="true"
                        />
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxDateBox
                            label="Ngày XB kế hoạch (*)"
                            labelMode="floating"
                            v-model="ThongTinChinhSuaLenh.NgayXuatBenKeHoach"
                            ref="KyTuNgay"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            :onValueChanged="onValueChangedLayNgay"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaGioXuatBen'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.NOTGIO'),
                                    ))
                            "
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>

                            <DxValidator ref="validateNgayXB">
                                <DxRequiredRule
                                    message="Ngày XB kế hoạch không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxDateBox
                            label="Giờ XB kế hoạch (*)"
                            v-model:value="ThongTinChinhSuaLenh.GioXB"
                            displayFormat="HH:mm"
                            type="time"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaGioXuatBen'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.NOTGIO'),
                                    ))
                            "
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator ref="validateGioXB">
                                <DxRequiredRule message="Giờ XB không được bỏ trống!" />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model:value="ThongTinChinhSuaLenh.IdDnvtXe"
                            ref="BienKiemSoat"
                            label="Biển kiểm soát (*)"
                            labelMode="floating"
                            :dataSource="{
                                store: DanhSachPhuongTien,
                                paginate: true,
                            }"
                            value-expr="ID_DnvtXe"
                            display-expr="BienKiemSoat"
                            :showClearButton="true"
                            :search-enabled="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :onItemClick="GanThongTinPhuongTien"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t(
                                        'QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaBienKiemSoat',
                                    ),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.PHUONGTIEN'),
                                    ))
                            "
                        >
                            <DxValidator ref="validateBienKiemSoat">
                                <DxRequiredRule
                                    message="Biển kiểm soát không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxDateBox
                            label="Hạn phù hiệu tuyến (*)"
                            labelMode="floating"
                            v-model:value="ThongTinChinhSuaLenh.HanPhuHieuTuyen"
                            ref="KyDenNgay"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            autocomplete="off"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaGiayToXe'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.PHUONGTIEN'),
                                    ))
                            "
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator ref="validationHanPH">
                                <DxRequiredRule
                                    message="Hạn phù hiệu không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="$Helper.Common.getEndDay(new Date())"
                                    message="Hạn phù hiệu phải lớn hơn ngày hiện tại!"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxDateBox
                            label="Hạn bảo hiểm xe (*)"
                            labelMode="floating"
                            v-model:value="ThongTinChinhSuaLenh.HanBaoHiem"
                            ref="KyDenNgay"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            autocomplete="off"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaGiayToXe'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.PHUONGTIEN'),
                                    ))
                            "
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator ref="validationBH">
                                <DxRequiredRule
                                    message="Hạn bảo hiểm xe không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="$Helper.Common.getEndDay(new Date())"
                                    message="Hạn bảo hiểm xe phải lớn hơn ngày hiện tại!"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxDateBox
                            label="Hạn đăng kiểm xe (*)"
                            labelMode="floating"
                            v-model:value="ThongTinChinhSuaLenh.HanDangKiem"
                            ref="KyDenNgay"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            autocomplete="off"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaGiayToXe'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.PHUONGTIEN'),
                                    ))
                            "
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator ref="validationDK">
                                <DxRequiredRule
                                    message="Hạn đăng kiểm xe không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="$Helper.Common.getEndDay(new Date())"
                                    message="Hạn đăng kiểm phải lớn hơn ngày hiện tại!"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model:value="ThongTinChinhSuaLenh.ID_DnvtLaiXe1"
                            ref="ID_DnvtLaiXe1"
                            label="Lái xe 1 (*)"
                            labelMode="floating"
                            :dataSource="{
                                store: DanhSachLaiXe1,
                                paginate: true,
                            }"
                            :display-expr="
                                (x) =>
                                    x && x.HoTen != ''
                                        ? `${x.HoTen} - ${x.SoGiayPhep}`
                                        : ''
                            "
                            value-expr="ID_DnvtLaiXe"
                            :showClearButton="true"
                            :search-enabled="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaLaiXe'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.LAIXE'),
                                    ))
                            "
                        >
                            <DxValidator ref="validateLaiXe1">
                                <DxRequiredRule message="Lái xe không được bỏ trống!" />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model:value="ThongTinChinhSuaLenh.ID_DnvtLaiXe2"
                            ref="ID_DnvtLaiXe2"
                            label="Lái xe 2"
                            labelMode="floating"
                            :dataSource="{
                                store: DanhSachLaiXe2,
                                paginate: true,
                            }"
                            :display-expr="
                                (x) =>
                                    x && x.HoTen != ''
                                        ? `${x.HoTen} - ${x.SoGiayPhep}`
                                        : ''
                            "
                            value-expr="ID_DnvtLaiXe"
                            :showClearButton="true"
                            :search-enabled="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaLaiXe'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.LAIXE'),
                                    ))
                            "
                        >
                            <!-- <DxValidator ref="validateLaiXe2">
                                <DxRequiredRule
                                    v-if="
                                        !ThongTinChinhSuaLenh.ID_DnvtLaiXe1 &&
                                        !ThongTinChinhSuaLenh.ID_DnvtLaiXe3
                                    "
                                    message="Biển kiểm soát không được bỏ trống"
                                />
                            </DxValidator> -->
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model:value="ThongTinChinhSuaLenh.ID_DnvtLaiXe3"
                            ref="ID_DnvtLaiXe3"
                            label="Lái xe 3"
                            labelMode="floating"
                            :dataSource="{
                                store: DanhSachLaiXe3,
                                paginate: true,
                            }"
                            :display-expr="
                                (x) =>
                                    x && x.HoTen != ''
                                        ? `${x.HoTen} - ${x.SoGiayPhep}`
                                        : ''
                            "
                            value-expr="ID_DnvtLaiXe"
                            :showClearButton="true"
                            :search-enabled="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaLaiXe'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t('MaCauHinhDuocPhepChinhSuaLenh.LAIXE'),
                                    ))
                            "
                        >
                            <!-- <DxValidator ref="validateLaiXe3">
                                <DxRequiredRule
                                    v-if="
                                        !ThongTinChinhSuaLenh.ID_DnvtLaiXe2 &&
                                        !ThongTinChinhSuaLenh.ID_DnvtLaiXe1
                                    "
                                    message="Biển kiểm soát không được bỏ trống"
                                />
                            </DxValidator> -->
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row mb-4 pb-4">
                    <div class="xs12">
                        <DxTextBox
                            label="Phụ xe"
                            labelMode="floating"
                            v-model:value="ThongTinChinhSuaLenh.NhanVienPhucVuTrenXe"
                            styling-mode="underlined"
                            :readOnly="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.ChinhSuaLenh_ChinhSuaLaiXe'),
                                ) ||
                                (YeuCauPhamViSuaLenh &&
                                    !checkQuyenChinhSua(
                                        $t(
                                            'MaCauHinhDuocPhepChinhSuaLenh.NHANVIENPHUCVU',
                                        ),
                                    ))
                            "
                        >
                            <DxValidator ref="validatePhuXe">
                                <DxStringLengthRule
                                    :max="48"
                                    message="Phụ xe không được lớn hơn 48 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
            </div>
            <div class="row p-fixed">
                <div class="xs12">
                    <div
                        class="row justify-space-between"
                        style="
                            border-top: 1px solid #dadce0;
                            padding: 0 10px;
                            height: 50px;
                            align-items: center;
                        "
                    >
                        <div class="text-xs-center">
                            <DxButton
                                text="Hủy"
                                type="danger"
                                styling-mode="text"
                                width="100%"
                                class="color-danger"
                                @click="$router.go(-1)"
                            />
                        </div>
                        <div class="text-xs-center">
                            <DxButton
                                text="Chỉnh sửa"
                                type="default"
                                styling-mode="contained"
                                class="color-primary"
                                @click="ValidateThongTin"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <PopupVue
                height="auto"
                v-if="ifDialogMaBaoMat"
                v-model:dialog="dialogMaBaoMat"
                title="Nhập mã bảo mật"
                buttonTextRight="Xác nhận"
                buttonTextLeft="Hủy"
                @close="XacNhanKyLenh"
                classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                :key="`${componentKey}-3`"
            >
                <template #content>
                    <PopupMatMaKyLenhVue ref="popupMatMaLenh" />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import {
    DxButton,
    DxTextBox,
    DxCheckBox,
    DxDateBox,
    DxValidator,
    DxSelectBox,
} from "devextreme-vue";
import {
    DxRangeRule,
    DxRequiredRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import PopupMatMaKyLenhVue from "../components/PopupMatMaKyLenh.vue";
import PopupVue from "../../../components/_Common/Popup.vue";

export default {
    components: {
        DxStringLengthRule,
        DxCheckBox,
        DxDateBox,
        DxButton,
        DxTextBox,
        DxValidator,
        DxSelectBox,
        DxRequiredRule,
        DxRangeRule,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        PopupVue,
        PopupMatMaKyLenhVue,
    },
    data() {
        return {
            DanhSachPhuongTien: [],
            laiXeDefault: {
                ID_LaiXe: null,
                TenLaiXe: "",
                HangGPLX: "",
                HanHieuLucGPLX: "",
                ID_DnvtLaiXe: null,
                HoTen: "",
                SoGiayPhep: "",
                HangGiayPhep: "",
                HanGiayPhep: "",
                NgayBatDauHopDong: "",
                NgayKetThucHopDong: "",
            },
            ifDialogMaBaoMat: false,
            dialogMaBaoMat: false,
        };
    },
    computed: {
        MaxDate() {
            return new Date(this.$t("DefaultNumBer.MaxDate"));
        },
        MinDate() {
            return new Date(this.$t("DefaultNumBer.MinDate"));
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
        ThongTinChinhSuaLenh: {
            get() {
                return this.$store.state.DVVTLenh.ThongTinChinhSuaLenh;
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "ThongTinChinhSuaLenh",
                    data: data,
                });
            },
        },
        DanhSachHangGPLX: {
            get() {
                return this.$store.state.ThongTin.DanhSachHangGPLX || {};
            },
            set(data) {
                this.$store.commit("ThongTin/Set", {
                    key: "DanhSachHangGPLX",
                    data: data,
                });
            },
        },
        DanhSachTatCaLaiXe() {
            return this.$store.state.ThongTin.DanhSachLaiXeTaoLenhTuyChon;
        },
        DanhSachLaiXe1() {
            return this.DanhSachTatCaLaiXe.filter(
                (e) =>
                    e.ID_DnvtLaiXe != this.ThongTinChinhSuaLenh.ID_DnvtLaiXe2 &&
                    e.ID_DnvtLaiXe != this.ThongTinChinhSuaLenh.ID_DnvtLaiXe3,
            );
        },
        DanhSachLaiXe2() {
            return this.DanhSachTatCaLaiXe.filter(
                (e) =>
                    e.ID_DnvtLaiXe != this.ThongTinChinhSuaLenh.ID_DnvtLaiXe1 &&
                    e.ID_DnvtLaiXe != this.ThongTinChinhSuaLenh.ID_DnvtLaiXe3,
            );
        },
        DanhSachLaiXe3() {
            return this.DanhSachTatCaLaiXe.filter(
                (e) =>
                    e.ID_DnvtLaiXe != this.ThongTinChinhSuaLenh.ID_DnvtLaiXe1 &&
                    e.ID_DnvtLaiXe != this.ThongTinChinhSuaLenh.ID_DnvtLaiXe2,
            );
        },
        DanhSachCauHinhDuocPhepChinhSuaLenh() {
            return this.$store.state.DVVTLenh.DanhSachCauHinhDuocPhepChinhSuaLenh;
        },
        YeuCauPhamViSuaLenh() {
            let DanhSachIdNhaXe = this.$Core.AuthApi.TokenParsed.DanhSachIdNhaXe || [];
            let IdLaiXe = this.$Core.AuthApi.TokenParsed.IdLaiXe;
            return DanhSachIdNhaXe.length > 0 || IdLaiXe;
        },
    },
    watch: {},
    methods: {
        checkQuyenChinhSua(TenTruongDuLieu) {
            return this.DanhSachCauHinhDuocPhepChinhSuaLenh.some(
                (e) => e.MaThuocTinh == TenTruongDuLieu,
            );
        },
        async LayDanhSachXe() {
            try {
                this.DanhSachPhuongTien = (
                    await this.$store.dispatch(
                        "ThongTin/Get_DanhSachPhuongTienTaoLenhTuyChon",
                        {
                            query: "00000000-0000-0000-0000-000000000000",
                        },
                    )
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách xe thất bại! Không thể kết nối máy chủ.`,
                );
            }
        },
        GanThongTinPhuongTien(item) {
            if (item.itemData) {
                this.ThongTinChinhSuaLenh.HanPhuHieuTuyen =
                    item.itemData && item.itemData.HanPhuHieu
                        ? this.$Helper.Common.getEndDay(item.itemData.HanPhuHieu)
                        : null;
                this.ThongTinChinhSuaLenh.HanBaoHiem =
                    item.itemData != null
                        ? this.$Helper.Common.getEndDay(item.itemData.HanBaoHiem)
                        : null;
                this.ThongTinChinhSuaLenh.HanDangKiem =
                    item.itemData != null
                        ? this.$Helper.Common.getEndDay(item.itemData.HanDangKiem)
                        : null;
                this.LayHanPhuHieuTuyen();
            }
        },
        async LayHanPhuHieuTuyen() {
            if (
                this.ThongTinChinhSuaLenh.IdDnvtXe &&
                this.ThongTinChinhSuaLenh.IdDnvtTuyen
            ) {
                try {
                    this.ThongTinChinhSuaLenh.HanPhuHieuTuyen = null;
                    let dataGet = {
                        IdDnvtXe: this.ThongTinChinhSuaLenh.IdDnvtXe,
                        IdDnvtTuyen: this.ThongTinChinhSuaLenh.IdDnvtTuyen,
                    };
                    let rs = await this.$Events.RequestGet({
                        ServicesBE: "QuanLyThongTinLenh",
                        url: this.$t("urlQLTTLenh.LayHanPhuHieuCuaXeTrenTuyen"),
                        dataGet: dataGet,
                        msgSuccess: "",
                        msgError: "Lấy hạn phù hiệu tuyến thất bại!",
                        msgCatch: "Lấy hạn phù hiệu tuyến thất bại!",
                    });
                    if (rs.status && rs.data) {
                        this.ThongTinChinhSuaLenh.HanPhuHieuTuyen = this.$Helper.Common.getEndDay(
                            rs.data,
                        );
                    } else if (rs.status && !rs.data) {
                        this.$Helper.ThongBaoToast(
                            "warning",
                            "Chưa có phù hiệu của xe trên tuyến đang chọn!",
                        );
                    }
                } catch (error) {
                    console.log("🚀 ~ error:", error);
                }
            }
        },
        async LayDanhSachLaiXe() {
            try {
                await this.$store.dispatch("ThongTin/Get_DanhSachLaiXeTaoLenhTuyChon");
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách lái xe thất bại! Không thể kết nối máy chủ.`,
                );
            }
        },
        async LayDanhSachHangGPLX() {
            try {
                await this.$store.dispatch("ThongTin/Get_DanhSachHangGPLX");
            } catch (ex) {
                console.log("🚀 ~ file: index.vue:588 ~ LayDanhSachHangGPLX ~ ex:", ex);
                this.$Helper.ThongBaoToast(
                    "error",
                    "Lấy danh sách hạng GPLX bị lỗi! Lỗi hệ thống client.",
                );
            }
        },
        async LayChiTietLenhCanSua() {
            try {
                let rs = await this.$store.dispatch("DVVTLenh/Get_ChiTietLenh", {
                    IdLenhDienTu: this.ThongTinChinhSuaLenh.IdLenhDienTu,
                });
                if (rs.Data.status == true) {
                    this.ThongTinChinhSuaLenh = {
                        ...this.ThongTinChinhSuaLenh,
                        ...rs.Data.data,
                        HanPhuHieuTuyen: new Date(rs.Data.data.HanPhuHieuTuyen),
                        HanBaoHiem: new Date(rs.Data.data.HanBaoHiem),
                        HanDangKiem: new Date(rs.Data.data.HanDangKiem),
                        NgayXuatBenKeHoach: new Date(rs.Data.data.GioXuatBen),
                        GioXB: new Date(rs.Data.data.GioXuatBen),
                        // DanhSachLaiXeGuiDi: GetDanhSachLaiXeGuiDi(this, rs.Data.data),
                        ID_DnvtLaiXe1: GetLaiXe(
                            this,
                            rs.Data.data.DanhSachLaiXe[0]?.GuidLaiXe || null,
                        ),
                        ID_DnvtLaiXe2: GetLaiXe(
                            this,
                            rs.Data.data.DanhSachLaiXe[1]?.GuidLaiXe || null,
                        ),
                        ID_DnvtLaiXe3: GetLaiXe(
                            this,
                            rs.Data.data.DanhSachLaiXe[2]?.GuidLaiXe || null,
                        ),
                        TuDongKyLenhKhiCanBoXacNhanKiemTraXe: true,
                        KyLaiLenh: true,
                    };
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Lấy chi tiết lệnh thất bại!",
                        message: rs.Data.message,
                    });
                }
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
            function GetLaiXe(self, GuidLaiXe) {
                if (!GuidLaiXe) {
                    return null;
                }
                let item = self.DanhSachTatCaLaiXe.find((e) => e.ID_LaiXe == GuidLaiXe);
                return item?.ID_DnvtLaiXe || null;
            }
            // function GetDanhSachLaiXeGuiDi(self, data) {
            //     let arr = [];
            //     for (var i = 0; i < 3; i++) {
            //         let tmpThongTinLaiXe = JSON.parse(JSON.stringify(self.laiXeDefault));
            //         if (data.DanhSachLaiXe[i]) {
            //             tmpThongTinLaiXe = self.DanhSachTatCaLaiXe.find(
            //                 (e) => e.ID_LaiXe == data.DanhSachLaiXe[i].GuidLaiXe,
            //             );
            //         }
            //         arr.push(tmpThongTinLaiXe);
            //     }
            //     return arr;
            // }
        },
        async ValidateThongTin() {
            let validateBienKiemSoat = this.$refs.validateBienKiemSoat.instance.validate();
            if (!validateBienKiemSoat.isValid) {
                return this.$Helper.Common.autoFocusError(validateBienKiemSoat);
            }
            let validationHanPH = this.$refs.validationHanPH.instance.validate();
            if (!validationHanPH.isValid) {
                return this.$Helper.Common.autoFocusError(validationHanPH);
            }
            let validationBH = this.$refs.validationBH.instance.validate();
            if (!validationBH.isValid) {
                return this.$Helper.Common.autoFocusError(validationBH);
            }
            let validationDK = this.$refs.validationDK.instance.validate();
            if (!validationDK.isValid) {
                return this.$Helper.Common.autoFocusError(validationDK);
            }
            let validateNgayXB = this.$refs.validateNgayXB.instance.validate();
            if (!validateNgayXB.isValid) {
                return this.$Helper.Common.autoFocusError(validateNgayXB);
            }
            let validateGioXB = this.$refs.validateGioXB.instance.validate();
            if (!validateGioXB.isValid) {
                return this.$Helper.Common.autoFocusError(validateGioXB);
            }
            let validateLaiXe1 = this.$refs.validateLaiXe1.instance.validate();
            if (!validateLaiXe1.isValid) {
                return this.$Helper.Common.autoFocusError(validateLaiXe1);
            }
            let validatePhuXe = this.$refs.validatePhuXe.instance.validate();
            if (!validatePhuXe.isValid) {
                return this.$Helper.Common.autoFocusError(validatePhuXe);
            }
            let rsCapNhatThongTinGiayToXe = await this.CapNhatThongTinGiayToXe();
            this.$stopLoading;
            if (rsCapNhatThongTinGiayToXe) {
                this.showPopupNhapMaBaoMat();
            }
        },
        async CapNhatThongTinGiayToXe() {
            try {
                const thongTinGiayToXe = {
                    iD_DnvtTuyen: this.ThongTinChinhSuaLenh.IdDnvtTuyen,
                    iD_DnvtXe: this.ThongTinChinhSuaLenh.IdDnvtXe,
                    hanBaoHiem: this.ThongTinChinhSuaLenh.HanBaoHiem,
                    hanDangKiem: this.ThongTinChinhSuaLenh.HanDangKiem,
                    hanPhuHieu: this.ThongTinChinhSuaLenh.HanPhuHieuTuyen,
                };
                let result = await this.$store.dispatch(
                    "ThongTin/CapNhatThongTinGiayToXe",
                    thongTinGiayToXe,
                );
                if (result.status == true) {
                    return true;
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                        message: `${result.message}`,
                    });
                    return false;
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoPopup({
                    message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                    message: "Lỗi hệ thống",
                });
                return false;
            }
        },
        showPopupNhapMaBaoMat() {
            this.ifDialogMaBaoMat = true;
            this.dialogMaBaoMat = true;
        },
        async XacNhanKyLenh(param) {
            if (!param) {
                this.dialogMaBaoMat = false;
                return;
            } else {
                let validMK = this.$refs.popupMatMaLenh.$refs.validateMaBaoMat.instance.validate();
                if (!validMK.isValid) {
                    return this.$Helper.Common.autoFocusError(validMK);
                } else {
                    this.dialogMaBaoMat = false;
                    this.ThucHienChinhSuaLenh();
                }
            }
        },
        async ThucHienChinhSuaLenh() {
            try {
                let rs = await this.$Events.RequestPost({
                    keyRequest: "ThucHienChinhSuaLenh",
                    ServicesBE: "QuanLyLenh",
                    url: this.$t("urlDNQuanLyLenh.ThucHienSuaThongTinLenh"),
                    dataPost: {
                        IdLenh: this.ThongTinChinhSuaLenh.IdLenhDienTu,
                        IdDnvtXe: this.ThongTinChinhSuaLenh.IdDnvtXe,
                        ID_LaiXeNhanLenh:
                            this.ThongTinChinhSuaLenh.ID_DnvtLaiXe1 ||
                            "00000000-0000-0000-0000-000000000000",
                        ID_LaiXeDiCungs: LayDanhSachLaiXeDiCung(this),
                        ThoiGianXuatBen: getNgayXuatBen({
                            NgayXuatBen: this.ThongTinChinhSuaLenh.NgayXuatBenKeHoach,
                            GioXuatBen: this.ThongTinChinhSuaLenh.GioXB,
                        }),
                        NhanVienPhucVu: this.ThongTinChinhSuaLenh.NhanVienPhucVuTrenXe,
                        KyLaiLenh: true,
                        KyLenhKhiCanBoXacNhanKiemTraXe:
                            this.ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                this.$t(
                                    "MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe",
                                )
                            ] == "true"
                                ? this.ThongTinChinhSuaLenh
                                      .TuDongKyLenhKhiCanBoXacNhanKiemTraXe
                                : false,
                        KyLaiLenh: this.$Helper.KiemTraQuyen(
                            this.$t("QuyenLenhDienTu.LENHDIENTU"),
                            this.$t("QuyenLenhDienTu.KyLaiLenh"),
                        )
                            ? this.ThongTinChinhSuaLenh.KyLaiLenh
                            : false,
                        MaBaoMat: this.$refs.popupMatMaLenh.MaBaoMat,
                    },
                    msgSuccess: "Chỉnh sửa thông tin lệnh thành công!",
                    msgError: "Chỉnh sửa thông tin lệnh thất bại!",
                    msgCatch: "Chỉnh sửa thông tin lệnh thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Popup",
                });
                if (rs.status) {
                    this.$router.push("/DNVT-Quan-Ly-Lenh");
                } else {
                    if (rs.errorCode == 401) {
                        this.NhapMaBaoMatLoi++;
                    }
                }
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }

            function getNgayXuatBen({ NgayXuatBen, GioXuatBen }) {
                let tmpNgayXuatBen = new Date(NgayXuatBen);
                tmpNgayXuatBen.setHours(new Date(GioXuatBen).getHours());
                tmpNgayXuatBen.setMinutes(new Date(GioXuatBen).getMinutes());
                tmpNgayXuatBen.setSeconds(0);
                tmpNgayXuatBen.setMilliseconds(0);
                return tmpNgayXuatBen;
            }

            function LayDanhSachLaiXeDiCung(self) {
                let arrLaiXe = [];
                if (self.ThongTinChinhSuaLenh.ID_DnvtLaiXe2) {
                    arrLaiXe.push(self.ThongTinChinhSuaLenh.ID_DnvtLaiXe2);
                }
                if (self.ThongTinChinhSuaLenh.ID_DnvtLaiXe3) {
                    arrLaiXe.push(self.ThongTinChinhSuaLenh.ID_DnvtLaiXe3);
                }
                return arrLaiXe;
            }
        },
        async LayPhamViDuLieuDuocPhepChinhSuaKhiSuaLenh() {
            try {
                if (this.YeuCauPhamViSuaLenh) {
                    let rs = await this.$store.dispatch(
                        "DVVTLenh/LayPhamViDuLieuDuocPhepChinhSuaKhiSuaLenh",
                        {
                            idLenhDienTu: this.ThongTinChinhSuaLenh.IdLenhDienTu,
                        },
                    );

                    if (!rs.status) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            "Lấy cấu hình chỉnh sửa lệnh thất bại! " + rs.message,
                        );
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            this.ifDialogMaBaoMat = false;
            this.LayPhamViDuLieuDuocPhepChinhSuaKhiSuaLenh();
            this.LayDanhSachXe();
            this.LayDanhSachLaiXe();
            await this.LayDanhSachHangGPLX();
            this.LayChiTietLenhCanSua();
        });
    },
    mounted() {},
};
</script>
<style scoped>
.p-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}
:deep(.dx-editor-underlined.dx-texteditor-with-floating-label:not(.dx-texteditor-empty)
        .dx-texteditor-label) {
    height: 17px;
    line-height: 19px;
    top: -2px;
}
:deep(.dx-editor-underlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active
        .dx-texteditor-label, .dx-editor-underlined.dx-texteditor-with-floating-label.dx-state-focused
        .dx-texteditor-label, .dx-editor-underlined.dx-texteditor-with-floating-label.dx-textarea.dx-state-focused
        .dx-texteditor-label) {
    height: 17px;
    line-height: 19px;
    top: -2px;
}
</style>
